import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34913004"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chatWrap" }
const _hoisted_2 = { class: "userListWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_User = _resolveComponent("User")!
  const _component_Empty = _resolveComponent("Empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, { title: "Message" }, {
      right: _withCtx(() => [
        _createVNode(_component_Popover, {
          overlay: true,
          "overlay-style": { background: 'rgba(0,0,0,0.4)' },
          placement: "bottom-end",
          show: _ctx.chat.showPopover,
          "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chat.showPopover) = $event)),
          actions: _ctx.chat.actions,
          onSelect: _ctx.chat.onSelect
        }, {
          reference: _withCtx(() => [
            _createVNode(_component_Icon, {
              name: "plus",
              size: "20",
              color: "#1296db"
            })
          ]),
          _: 1
        }, 8, ["overlay-style", "show", "actions", "onSelect"])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chat.chatList, (item) => {
        return (_openBlock(), _createBlock(_component_User, {
          key: item,
          chatType: item.chatType,
          lastMessage: item.lastMessage,
          name: _ctx.chat.formatSessionListTo(item.channel_id),
          unReadCount: item.unread_num
        }, null, 8, ["chatType", "lastMessage", "name", "unReadCount"]))
      }), 128)),
      (!_ctx.chat.chatList.length)
        ? (_openBlock(), _createBlock(_component_Empty, {
            key: 0,
            description: "会话列表为空"
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}