
import { Options, Vue, setup } from "vue-class-component";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { NavBar, Icon, Popover, Empty } from "vant";
import User from "@/components/user.vue";
import { EasemobChat } from "easemob-websdk/Easemob-chat";
import { useRouter, Router } from "vue-router";
import { formatSessionListTo } from "@/utils/im";
import { CHAT_TYPE, GROUP_SESSION } from "@/const";
import { AllState } from "@/store";

interface Actions {
  text: string;
}

interface ChatItemInfo extends EasemobChat.ConversationList {
  chatType: CHAT_TYPE;
}

@Options({
  components: {
    User,
    NavBar,
    Icon,
    Popover,
    Empty
  }
})
export default class Home extends Vue {
  chat = setup(() => {
    const store = useStore<AllState>();
    const chatList = ref<Array<ChatItemInfo>>([]);
    const showPopover = ref(false);
    const route: Router = useRouter();

    // 通过 actions 属性来定义菜单选项
    const actions: Array<Actions> = [{ text: "添加好友" }, { text: "设置" }];
    const onSelect = (action: Actions) => {
      console.log(action);
      route.push("/setting");
    };

    onMounted(() => {
      store.state.IM.connect.getConversationlist().then((res) => {
        let dt = res.data?.channel_infos;
        let conversations: any = [];
        dt?.forEach((item) => {
          let chatType =
            item.channel_id.indexOf(GROUP_SESSION) > -1
              ? CHAT_TYPE.groupChat
              : CHAT_TYPE.singleChat;

          conversations.push({
            ...item,
            chatType
          });
        });

        console.log(conversations, 'getConversationlist ');

        chatList.value = conversations;
      });
    });

    return {
      chatList,
      showPopover,
      actions,
      onSelect,
      formatSessionListTo
    };
  });
}
